
div.pagination {
    margin: auto;
    display: block;
}

button.pagination-page-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 1em 0em;
    padding: 0em 0.3em;
}

button.pagination-page-link.active {
    border-bottom: 2px solid #E98C3B;
}