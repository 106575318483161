
div.Personal {
    min-height: 100vh;
}

div.footer {
    clear: both;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5em;
    background-color: #ddd;
    padding: 1em;
}

button.log-out {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
}

button.log-out:hover {
    text-decoration: underline;
}