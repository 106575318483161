
div.Students {
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 80%;
    text-align: center;
}

div.Students table {
    box-shadow: 0 -1em 0 #E98C3B;
    margin-top: 1em;
    border-collapse: collapse;
    width: 100%;
}

div.Students tr {
    height: 4em;
}

div.Students tr.row-even {
    background-color: white;
}

div.Students tr.row-odd {
    background-color: #efefef;
}

div.Students i.student-check {
    color: green;
}

div.Students span.student-full-name {
    color: gray;
}

div.Students td.student-name-column {
    text-align: left;
}

div.Students td.student-group-column {
    text-align: left;
}

div.Students td.padding-column {
    width: 5%;
}