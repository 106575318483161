
div.Login {
    display: inline-block;
    padding: 200px 0 0 0;
    margin: 0;
    width: 500px;
    text-align: left;
}

img.Login-logo {
    width: 500px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #E98C3B !important;
    border-color: #E98C3B !important;
}

.Login-user-icon {
    width: 2em;
}